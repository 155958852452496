exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-a-propos-tsx": () => import("./../../../src/pages/a-propos.tsx" /* webpackChunkName: "component---src-pages-a-propos-tsx" */),
  "component---src-pages-contact-reservation-tsx": () => import("./../../../src/pages/contact-reservation.tsx" /* webpackChunkName: "component---src-pages-contact-reservation-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mentions-legales-tsx": () => import("./../../../src/pages/mentions-legales.tsx" /* webpackChunkName: "component---src-pages-mentions-legales-tsx" */),
  "component---src-pages-nos-prestations-tsx": () => import("./../../../src/pages/nos-prestations.tsx" /* webpackChunkName: "component---src-pages-nos-prestations-tsx" */),
  "component---src-pages-nos-tarifs-tsx": () => import("./../../../src/pages/nos-tarifs.tsx" /* webpackChunkName: "component---src-pages-nos-tarifs-tsx" */)
}

